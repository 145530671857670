import type { StdObject } from '~/types/internal'
import type { Section } from '~/types/section'

export const sectionQuery = (
  sectionName: string,
  query?: object,
  thumbs?: StdObject,
  placeholderData?: { data: Section }
) =>
  queryOptions({
    queryKey: ['sections', sectionName],
    queryFn: async (): Promise<{
      data: Section
    }> => {
      const res = await useCustomFetch<{ data: Section }>(
        `/public/sections/${sectionName}`,
        query,
        thumbs
      )
      return res?.data.value as { data: Section }
    },
    placeholderData,
  })
